import React, { useContext } from 'react'

import { ColsContext } from 'src/hooks/cols/context'

import Link from 'src/components/link/index'
import Button from 'src/components/admission/button'

import styles from './index.module.scss'

const Prices = () => {
	const { cols } = useContext(ColsContext)

	const narrow = cols === 'line_08'

	const send = (name) => {
		if (`gtag` in window) {
			window.gtag(`event`, `click`, {
				event_category: `formlink`,
				event_label: name,
				value: 1,
			})
		}
	}

	return (
		<div className={styles.prices}>
			<ul className={styles.planHead}>
				<li>
					<span className={styles.planName}>都立大学店</span>
					<span className={styles.monthlyPrice}>
						<span>¥7,980</span> / 月<small>（税込 ¥8,778）</small>
					</span>
					<Link
						to="https://entry.thefit.jp/join/000101/"
						className={styles.button}
						onClick={() => send(`toritsudai`)}
					>
						入会はこちら
					</Link>
				</li>
				<li>
					<span className={styles.campaign}>キャンペーン価格</span>
					<span className={styles.planName}>江古田店</span>
					<span className={styles.monthlyPrice}>
						<span>¥6,980</span> / 月<small>（税込 ¥7,678）</small>
					</span>
					<Link
						to="https://entry.thefit.jp/join/000102/"
						className={styles.button}
						onClick={() => send(`ekoda`)}
					>
						入会はこちら
					</Link>
				</li>
			</ul>

			<table>
				<thead>
					<tr>
						<th>&nbsp;</th>
						<th>
							<span className={styles.planName}>都立大学店</span>
							<span className={styles.monthlyPrice}>
								<span>¥7,980</span> / 月<small>（税込 ¥8,778）</small>
							</span>
							<Link
								to="https://entry.thefit.jp/join/000101/"
								className={styles.button}
								onClick={() => send(`toritsudai`)}
							>
								入会はこちら
							</Link>
						</th>
						<th>
							<span className={styles.campaign}>キャンペーン価格</span>
							<span className={styles.planName}>江古田店</span>
							<span className={styles.monthlyPrice}>
								<span>¥6,980</span> / 月<small>（税込 ¥7,678）</small>
							</span>
							<Link
								to="https://entry.thefit.jp/join/000102/"
								className={styles.button}
								onClick={() => send(`ekoda`)}
							>
								入会はこちら
							</Link>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>
							登録店舗での全てのワークアウト
							<wbr />
							マシーンとフロアの利用
						</th>
						<td className={styles.checked}>checked</td>
						<td className={styles.checked}>checked</td>
					</tr>
					<tr>
						<th>
							スタッフによるカウンセリングと
							<wbr />
							オリエンテーション
						</th>
						<td className={styles.checked}>checked</td>
						<td className={styles.checked}>checked</td>
					</tr>
					<tr>
						<th>初心者向けベーシックサポート</th>
						<td className={styles.checked}>checked</td>
						<td className={styles.checked}>checked</td>
					</tr>
					<tr>
						<th>体組成分析機の利用</th>
						<td className={styles.checked}>checked</td>
						<td className={styles.checked}>checked</td>
					</tr>
					<tr>
						<th>
							水素水サーバーの利用<sup>※1</sup>
						</th>

						{narrow ? (
							<React.Fragment>
								<td colSpan={2} className={styles.text}>
									<span>¥1,000</span>
									<small>（税込 ¥1,080）</small>/ 月
								</td>
							</React.Fragment>
						) : (
							<React.Fragment>
								<td className={styles.text}>
									<span>¥1,000</span>
									<small>（税込 ¥1,080）</small>/ 月
								</td>
								<td className={styles.text}>
									<span>¥1,000</span>
									<small>（税込 ¥1,080）</small>/ 月
								</td>
							</React.Fragment>
						)}
					</tr>
					<tr>
						<th>
							パーソナルロッカーの利用<sup>※2</sup>
						</th>

						{narrow ? (
							<React.Fragment>
								<td colSpan={2} className={styles.text}>
									<span>¥1,000</span>
									<small>（税込 ¥1,100）</small>/ 月
								</td>
							</React.Fragment>
						) : (
							<React.Fragment>
								<td className={styles.text}>
									<span>¥1,000</span>
									<small>（税込 ¥1,100）</small>/ 月
								</td>
								<td className={styles.text}>
									<span>¥1,000</span>
									<small>（税込 ¥1,100）</small>/ 月
								</td>
							</React.Fragment>
						)}
					</tr>
					<tr>
						<th>WEARETHEFIT相互利用</th>

						{narrow ? (
							<React.Fragment>
								<td colSpan={2} className={styles.text}>
									<span>¥1,000</span>
									<small>（税込 ¥1,100）</small>/ 月
								</td>
							</React.Fragment>
						) : (
							<React.Fragment>
								<td className={styles.text}>
									<span>¥1,000</span>
									<small>（税込 ¥1,100）</small>/ 月
								</td>
								<td className={styles.text}>
									<span>¥1,000</span>
									<small>（税込 ¥1,100）</small>/ 月
								</td>
							</React.Fragment>
						)}
					</tr>
					<tr>
						<th>
							AIマシンバイオサーキット<sup>※3</sup>
						</th>
						<td className={styles.text}>
							<span>¥2,020</span>
							<small>（税込 ¥2,222）</small>/ 月
						</td>
						<td></td>
					</tr>
					<tr>
						<th>
							セミパーソナル利用<sup>※3</sup>
						</th>
						<td className={styles.text}>
							<span>¥12,020</span>
							<small>（税込 ¥13,222）</small>/ 月
						</td>
						<td></td>
					</tr>
					<tr>
						<th>
							セルフエステ・
							<wbr />
							コラーゲンマシン利用<sup>※4</sup>
						</th>
						<td></td>
						<td className={styles.text}>
							<span>¥5,000</span>
							<small>（税込 ¥5,500）</small>/ 月
						</td>
					</tr>
				</tbody>
			</table>
			<div className={styles.caption}>
				<p>
					<span>※1</span>
					水素水サーバーは¥1,000<small>（税込¥1,080）</small>
					/月が基本料金になります。入会時の特典として「2か月無料」でご利用頂けます。
				</p>
				<p>
					<span>※2</span>パーソナルロッカーの利用は¥1,000<small>（税込¥1,100）</small>
					/月が基本料金になります。
				</p>
				<p>
					<span>※3</span>サービス提供店舗は都立大学店のみとなっております。
				</p>
				<p>
					<span>※4</span>女性限定のメニューになります。サービス提供店舗は江古田店のみになります
				</p>
			</div>
		</div>
	)
}

export default Prices
